.introduction {
  align-items: center;
  background-color: #3273dc !important;
  display: flex;
  flex-direction: column;
  height: 70vh;
  justify-content: center;
  padding: 1rem 3rem;
  position: relative;
}

.hybrid-title {
  color: #ffffffb3;
  font-size: 40px;
  left: 15px;
  position: absolute;
  top: 20px;
}

.start {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 80%;
}

.description {
  color: white;
  width: 50%;
}

.landing-title {
  font-size: 50px;
}

.landing-subtitle {
  font-size: 20px;
  margin-top: 3rem;
}

.waiting-list-box {
  align-items: center;
  background-color: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  font-size: 25px;
  margin-left: 3rem;
  padding: 2rem 3rem;
}

.form {
  display: flex;
  flex-direction: column;
}

.form label {
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 20px;
  margin-bottom: 2rem;
}

.waiting-list-title {
  margin-bottom: 2rem;
}

.waiting-list-box input {
  border: solid 1px #dbdbdb;
  border-radius: 5px;
  flex-grow: 1;
  font-size: 20px;
  padding: 8px 11px;
}

.waiting-list-box b {
  margin-right: 1rem;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #dbdbdb;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #dbdbdb;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #dbdbdb;
}

.waiting-list-box select {
  margin-left: 1rem;
}

.submit {
  background-color: black;
  color: #ffffff;
  cursor: pointer;
  margin-top: 1rem;
}

.submit:hover {
  opacity: 0.8;
  outline: none;
}

button:focus {
  outline: 0;
}

.checkbox {
  text-align: left;
  width: 100%;
}

.checkbox input:checked ~ .checkmark {
  background-color: black;
}

.help {
  text-align: right;
}

.learn-more {
  background-color: white;
  border: solid 1px rgb(26, 50, 187);
  border-radius: 0.2rem;
  font-weight: 700;
  padding: 0.5rem 0.8rem 0.5rem 0.8rem;
}

.learn-more:hover {
  cursor: pointer;
}

.data-number {
  font-size: 40px;
}

.hybrid-color-text {
  color: rgb(26, 50, 187);
}

.cards-container {
  padding: 0 4rem 0 4rem;
}

.landing-column {
  padding: 2rem;
}

.card {
  text-align: left;
}

.landing-column .card {
  border-radius: 0.5rem;
  padding: 1rem;
}

.landing-column .card:hover {
  background-color: rgb(26, 50, 187);
  color: white !important;
}

.landing-level {
  max-width: 700px;
  margin: auto;
}

.landing-info-section {
  background-color: #93bcee17;
}

.landing-info-section .columns {
  margin: auto;
  max-width: 900px;
}

.landing-info-section .column {
  padding: 5% 5% 50px 5%;
  text-align: left;
}

.landing-info-section .business-mobile {
  display: none;
}

.landing-info-section .title {
  padding-bottom: 2rem;
}

.landing-info-section .image {
  margin: auto;
  max-width: 400px;
}

.landing-video {
  padding: 0;
}

.footer {
  align-items: center;
  background-color: rgb(26, 50, 187) !important;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footer-logo {
  font-size: 50px;
  margin-left: 5rem;
  text-align: left;
}

.footer-info {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 5rem;
  width: 50%;
}

.info-details {
  margin-right: 10rem;
}

.info-details b {
  font-size: 20px;
}

.info-details ul {
  margin-top: 1rem;
}

.error {
  font-size: 15px;
  color: red;
  margin-top: 0.5rem;
}

.success {
  font-size: 15px;
  color: blue;
  margin-top: 0.5rem;
}

.is-6 {
  min-height: 120px;
}

@media screen and (max-width: 1200px) {
  .start {
    width: 90%;
  }

  .description {
    line-height: 2.5rem;
  }

  .landing-subtitle {
    margin-top: 1rem;
    line-height: 1.8rem;
  }

  .waiting-list-title {
    margin-bottom: 1rem;
  }

  .form label {
    margin-bottom: 1rem;
  }

  .cards-container {
    padding: 0;
  }

  .info-details {
    margin-right: 5rem;
  }
}

@media screen and (max-width: 1024px) {
  .landing-title {
    font-size: 40px;
  }

  .landing-subtitle {
    font-size: 18px;
    margin-top: 3rem;
  }

  .waiting-list-box {
    font-size: 20px;
    padding: 1rem 2rem;
  }

  .form label {
    font-size: 17px;
    margin-bottom: 1rem;
  }

  .waiting-list-box input {
    font-size: 17px;
    margin-left: 0.5rem;
    padding: 6px 9px;
  }

  .info-details {
    margin-right: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .introduction {
    height: 100vh;
  }

  .description {
    text-align: center;
    width: 90%;
  }

  .start {
    flex-direction: column;
    margin-top: 5rem;
  }

  .waiting-list-box {
    margin-left: 0;
    margin-top: 3rem;
  }

  .landing-info-section .columns {
    display: flex;
    flex-direction: column;
    padding: 0 1rem 0 1rem;
  }

  .landing-info-section .column {
    padding: 5% 0 0 0;
    text-align: center;
  }

  .landing-info-section .business {
    display: none;
  }

  .landing-info-section .business-mobile {
    display: inline-block;
  }

  .landing-info-section .title {
    padding: 3rem 0 1rem 0;
  }

  .landing-info-section .image {
    max-width: 200px;
  }

  .data-number {
    font-size: 30px;
  }

  .footer-logo {
    font-size: 40px;
    margin-left: 3rem;
  }

  .footer-info {
    margin-right: 3rem;
  }
}

@media screen and (max-width: 640px) {
  .hybrid-title {
    font-size: 30px;
  }

  .landing-title {
    font-size: 30px;
    line-height: 1rem;
  }

  .landing-subtitle {
    font-size: 15px;
    line-height: 1.3rem;
    margin-top: 0.8rem;
  }

  .waiting-list-box {
    font-size: 16px;
  }

  .form label {
    font-size: 13px;
    margin-bottom: 0.3rem;
  }

  .waiting-list-box input {
    font-size: 13px;
  }

  .submit {
    margin-top: 0.3rem;
  }

  .landing-level {
    display: flex;
    flex-direction: column;
  }

  .level-item {
    margin-top: 1rem;
  }

  .data-number {
    font-size: 25px;
  }

  .footer {
    flex-direction: column-reverse;
  }

  .footer-logo {
    font-size: 40px;
    margin-left: 0;
    margin-top: 1.5rem;
  }

  .footer-info {
    justify-content: center;
    margin-left: 2.5rem;
    margin-right: 0;
    text-align: center;
    width: 100%;
  }

  .info-details b {
    font-size: 18px;
  }

  .info-details ul {
    font-size: 14px;
    margin-top: 0.5rem;
  }
}
