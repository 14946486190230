body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif;
  margin: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

code {
  font-family: source-code-prop, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.level-item {
  margin-top: 1rem;
  min-width: 325px !important
}
.level {
  justify-content: space-around !important
}
